import React from "react"
import { graphql } from "gatsby"

import {
  Wrapper,
  WrapperHeader,
  WrapperMainHeading,
} from "../../components/styled/Wrapper"
import Layout from "../../components/layout/Main"
import Tag from "../../components/Tag"
import styled from "@emotion/styled"

const TagHeader = styled.h1`
  margin-bottom: 10px;
`

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Wrapper>
      <WrapperHeader>
        <WrapperMainHeading>Oznake</WrapperMainHeading>
      </WrapperHeader>
      <ul className="taglist">
        {group.map((tag, i) => (
          <Tag key={i} to={`/oznake/${tag.fieldValue}/`}>
            {tag.fieldValue}
          </Tag>
        ))}
      </ul>
    </Wrapper>
  </Layout>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
